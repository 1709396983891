::-moz-selection {
  background: #3498db;
  color: #fff;
  text-shadow: none;
}
::selection {
  background: #3498db;
  color: #fff;
  text-shadow: none;
}

a {
  color: #3498db;
}

a:hover {
  color: #2980b9;
}

footer .nav-list li a:hover,
footer ul.regular li a:hover {
  color: #2980b9;
}

.btn-link:hover {
  color: #2980b9;
}

.btn-theme {
  background: #3498db;
}
.btn-theme:hover,
.btn-theme.active,
.btn-theme.disabled,
.btn-theme[disabled] {
  background: #2980b9;
  *background: #2980b9;
}

.rheading span {
  background: #3498db;
}
/* sequence slider */

.info h2 {
  background: #3498db;
}

/* iview slider */
.iview-caption.caption-theme {
  background: #3498db;
}

/* flexslider */
.flex-caption {
  border-bottom: 2px solid #3498db;
}

/* fontawesome icons */
.icon-square:hover,
.icon-rounded:hover,
.icon-circled:hover {
  background-color: #3498db;
}
[class^='icon-'].active,
[class*=' icon-'].active {
  background-color: #3498db;
}

/* header */
.navbar .nav li.active a {
  background: #3498db;
}
.navbar .nav li.active a:hover {
  background: #2980b9;
}

.color {
  background: #3498db;
  color: #fff;
}

textarea:focus,
input[type='text']:focus,
input[type='password']:focus,
input[type='datetime']:focus,
input[type='datetime-local']:focus,
input[type='date']:focus,
input[type='month']:focus,
input[type='time']:focus,
input[type='week']:focus,
input[type='number']:focus,
input[type='email']:focus,
input[type='url']:focus,
input[type='search']:focus,
input[type='tel']:focus,
input[type='color']:focus,
.uneditable-input:focus {
  border-color: #3498db;
  outline: 0;
  outline: thin dotted \9;
  /* IE6-9 */
}

/* sequence slider */
.sequence-nav .next:hover,
.sequence-nav .prev:hover {
  background: #3498db;
}

/* jcarousel */
.jcarousel-skin-tango .jcarousel-prev-disabled-horizontal,
.jcarousel-skin-tango .jcarousel-prev-disabled-horizontal:hover,
.jcarousel-skin-tango .jcarousel-prev-disabled-horizontal:focus,
.jcarousel-skin-tango .jcarousel-prev-disabled-horizontal:active {
  background-color: #3498db;
}

/* breadcrumb */
.breadcrumb {
}

/* call action */
.call-action {
  border-left: 5px solid #3498db;
}

/* box */

.features:hover .box {
  border-bottom: 2px solid #3498db;
}

/* accordion */
.accordion-heading a:hover {
  color: #3498db;
}
.accordion-heading .active {
  color: #3498db;
}

/* tab */
.tabs-top .nav-tabs > li.active > a:hover,
.tabs-top .nav-tabs > li.active > a {
  border-top-color: #3498db;
}

/* pricing box */
.priceBox.special .emphasis {
  background: #3498db;
}

/* tagline */
.tagline {
  border-bottom: 5px solid #3498db;
}

/* button */
.btn-link {
  color: #3498db;
}

.nav .dropdown-toggle .caret {
  margin-top: 6px;
  border-top-color: #3498db;
  border-bottom-color: #3498db;
}

/* tabs */
.nav-pills > .active > a,
.nav-pills > .active > a:hover {
  color: #ffffff;
  background-color: #3498db;
}

/* accordion */
.accordion-heading a.accordion-toggle:hover {
  color: #3498db;
}

/* label */
.label-orange,
.badge-orange {
  background-color: #3498db;
}

/* nav */
.nav-list > .active > a,
.nav-list > .active > a:hover {
  background: #3498db;
}

.nav-pills > .active > a,
.nav-pills > .active > a:hover {
  background: #3498db;
}

.nav .dropdown-toggle .caret {
  border-top-color: #3498db;
  border-bottom-color: #3498db;
}

/* custom progress bar according template skin */
.progress-orange .bar,
.progress .bar-orange {
  background: #3498db;
}

.progress-orange.progress-striped .bar,
.progress-striped .bar-orange {
  background: #3498db;
}

/* pagination */

.pagination ul li a:hover {
  background: #3498db;
  border-color: #3498db;
}

/* widget */
aside .widget ul.cat li a:hover {
  color: #3498db;
}

aside .widget ul.tags li a {
  background: #3498db;
}

aside .widget ul.recent-posts li a:hover {
  color: #3498db;
}

/* flickr footer badge */

footer .widget .flickr-badge img:hover {
  border: 2px solid #3498db;
}

/* blog */

.post-meta {
  border-left: 2px solid #3498db;
}

.entry-body a h5.title:hover {
  color: #3498db;
}

article.blog-post .post-heading h3 a:hover {
  color: #3498db;
}

article.last-post {
  background: #3498db;
}

ul.time-post li.year {
  border-bottom: 1px solid #3498db;
}

section#subintro {
  background: #3498db;
}

/* footer */

/* 404 */

.error-body {
  background: #3498db;
  padding: 20px;
  text-align: center;
}

/* misc */
a.thumbnail:hover {
  border-color: #3498db;
}

ul.filter li.active a:hover,
.flex-control-nav li a:hover,
.flex-control-nav li a.active {
  background: #3498db;
}

.thumbnail h4 a:hover,
.thumbnail .meta span a:hover,
ul.recent li h6 a:hover,
ul.post-meta li a:hover,
.media-body span,
.media-body .media-heading a:hover,
form label span,
article.last-post h5 a:hover {
  color: #3498db;
  text-decoration: none;
}
