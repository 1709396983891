::selection {
  background: #3498db;
  color: #fff;
  text-shadow: none;
}

a {
  color: #3498db;
}

a:hover {
  color: #2980b9;
}

footer .nav-list li a:hover,
footer ul.regular li a:hover {
  color: #2980b9;
}

.btn-link:hover {
  color: #2980b9;
}

.btn-theme {
  background: #3498db;
}
.btn-theme:hover,
.btn-theme.active,
.btn-theme.disabled,
.btn-theme[disabled] {
  background: #2980b9;
  *background: #2980b9;
}

.rheading span {
  background: #3498db;
}
/* sequence slider */

.info h2 {
  background: #3498db;
}

/* iview slider */
.iview-caption.caption-theme {
  background: #3498db;
}

/* flexslider */
.flex-caption {
  border-bottom: 2px solid #3498db;
}

/* fontawesome icons */
.icon-square:hover,
.icon-rounded:hover,
.icon-circled:hover {
  background-color: #3498db;
}
[class^='icon-'].active,
[class*=' icon-'].active {
  background-color: #3498db;
}

/* header */
.navbar .nav li.active a {
  background: #3498db;
}
.navbar .nav li.active a:hover {
  background: #2980b9;
}

.color {
  background: #3498db;
  color: #fff;
}

textarea:focus,
input[type='text']:focus,
input[type='password']:focus,
input[type='datetime']:focus,
input[type='datetime-local']:focus,
input[type='date']:focus,
input[type='month']:focus,
input[type='time']:focus,
input[type='week']:focus,
input[type='number']:focus,
input[type='email']:focus,
input[type='url']:focus,
input[type='search']:focus,
input[type='tel']:focus,
input[type='color']:focus,
.uneditable-input:focus {
  border-color: #3498db;
  outline: 0;
  outline: thin dotted \9;
  /* IE6-9 */
}

/* sequence slider */
.sequence-nav .next:hover,
.sequence-nav .prev:hover {
  background: #3498db;
}

/* jcarousel */
.jcarousel-skin-tango .jcarousel-prev-disabled-horizontal,
.jcarousel-skin-tango .jcarousel-prev-disabled-horizontal:hover,
.jcarousel-skin-tango .jcarousel-prev-disabled-horizontal:focus,
.jcarousel-skin-tango .jcarousel-prev-disabled-horizontal:active {
  background-color: #3498db;
}

/* breadcrumb */
.breadcrumb {
}

/* call action */
.call-action {
  border-left: 5px solid #3498db;
}

/* box */

.features:hover .box {
  border-bottom: 2px solid #3498db;
}

/* accordion */
.accordion-heading a:hover {
  color: #3498db;
}
.accordion-heading .active {
  color: #3498db;
}

/* tab */
.tabs-top .nav-tabs > li.active > a:hover,
.tabs-top .nav-tabs > li.active > a {
  border-top-color: #3498db;
}

/* pricing box */
.priceBox.special .emphasis {
  background: #3498db;
}

/* tagline */
.tagline {
  border-bottom: 5px solid #3498db;
}

/* button */
.btn-link {
  color: #3498db;
}

.nav .dropdown-toggle .caret {
  margin-top: 6px;
  border-top-color: #3498db;
  border-bottom-color: #3498db;
}

/* tabs */
.nav-pills > .active > a,
.nav-pills > .active > a:hover {
  color: #ffffff;
  background-color: #3498db;
}

/* accordion */
.accordion-heading a.accordion-toggle:hover {
  color: #3498db;
}

/* label */
.label-orange,
.badge-orange {
  background-color: #3498db;
}

/* nav */
.nav-list > .active > a,
.nav-list > .active > a:hover {
  background: #3498db;
}

.nav-pills > .active > a,
.nav-pills > .active > a:hover {
  background: #3498db;
}

.nav .dropdown-toggle .caret {
  border-top-color: #3498db;
  border-bottom-color: #3498db;
}

/* custom progress bar according template skin */
.progress-orange .bar,
.progress .bar-orange {
  background: #3498db;
}

.progress-orange.progress-striped .bar,
.progress-striped .bar-orange {
  background: #3498db;
}

/* pagination */

.pagination ul li a:hover {
  background: #3498db;
  border-color: #3498db;
}

/* widget */
aside .widget ul.cat li a:hover {
  color: #3498db;
}

aside .widget ul.tags li a {
  background: #3498db;
}

aside .widget ul.recent-posts li a:hover {
  color: #3498db;
}

/* flickr footer badge */

footer .widget .flickr-badge img:hover {
  border: 2px solid #3498db;
}

/* blog */

.post-meta {
  border-left: 2px solid #3498db;
}

.entry-body a h5.title:hover {
  color: #3498db;
}

article.blog-post .post-heading h3 a:hover {
  color: #3498db;
}

article.last-post {
  background: #3498db;
}

ul.time-post li.year {
  border-bottom: 1px solid #3498db;
}

section#subintro {
  background: #3498db;
}

/* footer */

/* 404 */

.error-body {
  background: #3498db;
  padding: 20px;
  text-align: center;
}

/* misc */
a.thumbnail:hover {
  border-color: #3498db;
}

ul.filter li.active a:hover,
.flex-control-nav li a:hover,
.flex-control-nav li a.active {
  background: #3498db;
}

.thumbnail h4 a:hover,
.thumbnail .meta span a:hover,
ul.recent li h6 a:hover,
ul.post-meta li a:hover,
.media-body span,
.media-body .media-heading a:hover,
form label span,
article.last-post h5 a:hover {
  color: #3498db;
  text-decoration: none;
}

img {
  /* Responsive images (ensure images don't scale beyond their parents) */
  max-width: 100%;
  /* Part 1: Set a maxium relative to the parent */
  width: auto\9;
  /* IE7-8 need help adjusting responsive images */
  height: auto;
  /* Part 2: Scale the height according to the width, otherwise you get stretching */
  vertical-align: middle;
  border: 0;
  -ms-interpolation-mode: bicubic;
}

.hide-text {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.input-block-level {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  font-size: 15px;
  line-height: 1.6em;
  color: #666;
  background-color: #ffffff;
}

p {
  margin: 0 0 15px;
}

.well ul {
  list-style: none;
  margin-left: 0;
}

a {
  color: #0088cc;
  text-decoration: none;
}

a:hover {
  color: #005580;
  text-decoration: underline;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #333;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  line-height: 1.5em;
  margin-top: 0;
  margin-bottom: 15px;
}

h1 {
  font-size: 30px;
}

h2 {
  font-size: 26px;
}

h3 {
  font-size: 22px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 16px;
}

h6 {
  font-size: 14px;
}

h1 small {
  font-size: 24.5px;
}

h2 small {
  font-size: 17.5px;
}

h3 small {
  font-size: 14px;
}

h4 small {
  font-size: 14px;
}

.img-rounded {
  border-radius: 0;
}

.img-polaroid {
  padding: 4px;
  background-color: #fff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: none;
}

.img-circle {
  border: 1px solid rgba(0, 0, 0, 0.2);
}

code,
pre {
  border-radius: 0;
}

code {
  border: 1px solid #e1e1e8;
  white-space: nowrap;
}

pre {
  border-radius: 0;
}

pre code {
  padding: 0;
  color: inherit;
  white-space: pre;
  white-space: pre-wrap;
  background-color: transparent;
  border: 0;
}

blockquote {
  padding: 0;
  border: none;
  page-break-inside: avoid;
}

fieldset {
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  border: 0;
  border-bottom: 1px solid #e5e5e5;
}

select,
textarea,
input[type='text'],
input[type='password'],
input[type='datetime'],
input[type='datetime-local'],
input[type='date'],
input[type='month'],
input[type='time'],
input[type='week'],
input[type='number'],
input[type='email'],
input[type='url'],
input[type='search'],
input[type='tel'],
input[type='color'],
.uneditable-input {
  border-radius: 2px;
}

textarea,
input[type='text'],
input[type='password'],
input[type='datetime'],
input[type='datetime-local'],
input[type='date'],
input[type='month'],
input[type='time'],
input[type='week'],
input[type='number'],
input[type='email'],
input[type='url'],
input[type='search'],
input[type='tel'],
input[type='color'],
.uneditable-input {
  box-shadow: none;
}

textarea:focus,
input[type='text']:focus,
input[type='password']:focus,
input[type='datetime']:focus,
input[type='datetime-local']:focus,
input[type='date']:focus,
input[type='month']:focus,
input[type='time']:focus,
input[type='week']:focus,
input[type='number']:focus,
input[type='email']:focus,
input[type='url']:focus,
input[type='search']:focus,
input[type='tel']:focus,
input[type='color']:focus,
.uneditable-input:focus {
  border-color: rgba(82, 168, 236, 0.8);
  outline: 0;
  outline: thin dotted \9;
  /* IE6-9 */
  box-shadow: none;
}

.uneditable-input,
.uneditable-textarea {
  box-shadow: none;
  cursor: not-allowed;
}

input[disabled],
select[disabled],
textarea[disabled],
input[readonly],
select[readonly],
textarea[readonly] {
  cursor: not-allowed;
  background-color: #eeeeee;
}

input[type='radio'][disabled],
input[type='checkbox'][disabled],
input[type='radio'][readonly],
input[type='checkbox'][readonly] {
  background-color: transparent;
}

.control-group.warning input,
.control-group.warning select,
.control-group.warning textarea {
  box-shadow: none;
}

.control-group.warning input:focus,
.control-group.warning select:focus,
.control-group.warning textarea:focus {
  box-shadow: none;
}

.control-group.error input,
.control-group.error select,
.control-group.error textarea {
  box-shadow: none;
}

.control-group.error input:focus,
.control-group.error select:focus,
.control-group.error textarea:focus {
  box-shadow: none;
}

.control-group.success input,
.control-group.success select,
.control-group.success textarea {
  box-shadow: none;
}

.control-group.success input:focus,
.control-group.success select:focus,
.control-group.success textarea:focus {
  box-shadow: none;
}

.control-group.info input,
.control-group.info select,
.control-group.info textarea {
  box-shadow: none;
}

.control-group.info input:focus,
.control-group.info select:focus,
.control-group.info textarea:focus {
  box-shadow: none;
}

input:focus:invalid:focus,
textarea:focus:invalid:focus,
select:focus:invalid:focus {
  box-shadow: none;
}

.input-append .form-search input,
.input-append .form-search button {
  border: none;
}

.input-append input,
.input-prepend input,
.input-append select,
.input-prepend select,
.input-append .uneditable-input,
.input-prepend .uneditable-input {
  border-radius: 2px;
}

.input-append .add-on,
.input-prepend .add-on,
.input-append .btn,
.input-prepend .btn,
.input-append .btn-group > .dropdown-toggle,
.input-prepend .btn-group > .dropdown-toggle {
  vertical-align: top;
  border-radius: 2px;
}

.input-prepend .add-on:first-child,
.input-prepend .btn:first-child {
  border-radius: 0;
}

.input-append input,
.input-append select,
.input-append .uneditable-input {
  border-radius: 0;
}

.input-append input + .btn-group .btn:last-child,
.input-append select + .btn-group .btn:last-child,
.input-append .uneditable-input + .btn-group .btn:last-child {
  border-radius: 0;
}

.input-append .add-on:last-child,
.input-append .btn:last-child,
.input-append .btn-group:last-child > .dropdown-toggle {
  border-radius: 0;
}

.input-prepend.input-append input,
.input-prepend.input-append select,
.input-prepend.input-append .uneditable-input {
  border-radius: 0;
  border: none;
}

.input-prepend.input-append input + .btn-group .btn,
.input-prepend.input-append select + .btn-group .btn,
.input-prepend.input-append .uneditable-input + .btn-group .btn {
  border-radius: 0;
}

.input-prepend.input-append .add-on:first-child,
.input-prepend.input-append .btn:first-child {
  border-radius: 0;
}

.input-prepend.input-append .add-on:last-child,
.input-prepend.input-append .btn:last-child {
  border-radius: 0;
}

.input-prepend.input-append .btn-group:first-child {
  margin-left: 0;
}

input.search-query {
  margin-bottom: 0;
  border-radius: 0;
}

/* Allow for input prepend/append in search forms */
.form-search .input-append .search-query,
.form-search .input-prepend .search-query {
  border-radius: 0;
}

.form-search .input-append .search-query {
  border-radius: 0;
}

.form-search .input-append .btn {
  border-radius: 0;
}

.form-search .input-prepend .search-query {
  border-radius: 0;
}

.form-search .input-prepend .btn {
  border-radius: 0;
}

.table-bordered {
  border-left: 0;
  border-radius: 0;
}

.table-bordered caption + thead tr:first-child th,
.table-bordered caption + tbody tr:first-child th,
.table-bordered caption + tbody tr:first-child td,
.table-bordered colgroup + thead tr:first-child th,
.table-bordered colgroup + tbody tr:first-child th,
.table-bordered colgroup + tbody tr:first-child td,
.table-bordered thead:first-child tr:first-child th,
.table-bordered tbody:first-child tr:first-child th,
.table-bordered tbody:first-child tr:first-child td {
  border-top: 0;
}

.table-bordered thead:first-child tr:first-child > th:first-child,
.table-bordered tbody:first-child tr:first-child > td:first-child {
  border-top-left-radius: 0;
}

.table-bordered thead:first-child tr:first-child > th:last-child,
.table-bordered tbody:first-child tr:first-child > td:last-child {
  border-top-right-radius: 0;
}

.table-bordered thead:last-child tr:last-child > th:first-child,
.table-bordered tbody:last-child tr:last-child > td:first-child,
.table-bordered tfoot:last-child tr:last-child > td:first-child {
  border-bottom-left-radius: 0;
}

.table-bordered thead:last-child tr:last-child > th:last-child,
.table-bordered tbody:last-child tr:last-child > td:last-child,
.table-bordered tfoot:last-child tr:last-child > td:last-child {
  border-bottom-right-radius: 0;
}

.table-bordered tfoot + tbody:last-child tr:last-child td:first-child {
  border-bottom-left-radius: 0;
}

.table-bordered tfoot + tbody:last-child tr:last-child td:last-child {
  border-bottom-right-radius: 0;
}

.table-bordered caption + thead tr:first-child th:first-child,
.table-bordered caption + tbody tr:first-child td:first-child,
.table-bordered colgroup + thead tr:first-child th:first-child,
.table-bordered colgroup + tbody tr:first-child td:first-child {
  border-top-left-radius: 0;
}

.table-bordered caption + thead tr:first-child th:last-child,
.table-bordered caption + tbody tr:first-child td:last-child,
.table-bordered colgroup + thead tr:first-child th:last-child,
.table-bordered colgroup + tbody tr:first-child td:last-child {
  border-top-right-radius: 0;
}

.caret {
  border-top: 4px solid #000000;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  content: '';
}

.dropdown-menu {
  border-radius: 0;
  box-shadow: none;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
}

.dropdown-menu.pull-right {
  right: 0;
  left: auto;
}

.dropdown-menu .disabled > a:hover {
  text-decoration: none;
  background-color: transparent;
  background-image: none;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  cursor: default;
}

.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px solid #000000;
  content: '';
}

.dropdown-submenu > .dropdown-menu {
  border-radius: 0;
}

.dropup .dropdown-submenu > .dropdown-menu {
  border-radius: 0;
}

.dropdown-submenu.pull-left > .dropdown-menu {
  border-radius: 0;
}

.typeahead {
  border-radius: 0;
}

.well {
  border: none;
  border-radius: 0;
  box-shadow: none;
}

.well-large {
  border-radius: 0;
}

.well-small {
  border-radius: 0;
}

button.close {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}

.btn {
  display: inline-block;
  *display: inline;
  /* IE7 inline-block hack */
  *zoom: 1;
  padding: 4px 12px;
  margin-bottom: 0;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  color: #333333;
  text-shadow: none;
  background-color: #e6e6e6;
  background-image: none;
  border: none;
  *border: 0;
  *margin-left: 0.3em;
  box-shadow: none;
  border-radius: 2px;
}

/* button flat */
.btn-flat {
  border-radius: 0;
}

/* button rounded */
.btn-rounded {
  border-radius: 8px;
}

.btn:hover,
.btn:active,
.btn.active,
.btn.disabled,
.btn[disabled] {
  color: #333333;
  background-color: #e6e6e6;
  *background-color: #d9d9d9;
}

.btn:active,
.btn.active {
  background-color: #cccccc \9;
}

.btn:first-child {
  *margin-left: 0;
}

.btn:hover {
  color: #333333;
  text-decoration: none;
  background-position: 0 -15px;
}

.btn:focus {
  outline: thin dotted #333;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.btn.active,
.btn:active {
  background-image: none;
  outline: 0;
  box-shadow: none;
}

.btn.disabled,
.btn[disabled] {
  cursor: default;
  background-image: none;
  opacity: 0.65;
  filter: alpha(opacity=65);
  box-shadow: none;
}

.btn-large {
  padding: 11px 19px;
  font-size: 17.5px;
  border-radius: 2px;
}

.btn-large [class^='icon-'],
.btn-large [class*=' icon-'] {
  margin-top: 4px;
}

.btn-small {
  padding: 2px 10px;
  font-size: 11.9px;
  border-radius: 2px;
}

.btn-small [class^='icon-'],
.btn-small [class*=' icon-'] {
  margin-top: 0;
}

.btn-mini [class^='icon-'],
.btn-mini [class*=' icon-'] {
  margin-top: -1px;
}

.btn-mini {
  padding: 0 6px;
  font-size: 10.5px;
  border-radius: 2px;
}

.btn-block {
  display: block;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  box-sizing: border-box;
}

/*=====================================*/
/* nav */
.nav-list > .active > a,
.nav-list > .active > a:hover {
  background: #0072c6;
}

.nav-pills > .active > a,
.nav-pills > .active > a:hover {
  background: #0072c6;
}

.nav .dropdown-toggle .caret {
  border-top-color: #0072c6;
  border-bottom-color: #0072c6;
}

/* header */

.navbar .nav li.active a {
  font-weight: bold;
}
header .navbar-primary .navbar-inner nav li.blob {
  border-top: 5px solid #0072c6;
  margin-top: -5px;
}

.navbar-inner {
  min-height: 40px;
  padding-right: 20px;
  padding-left: 20px;
  background: #fff;
  background-image: none;
  border: none;
  border-radius: 0;
  *zoom: 1;
  box-shadow: none;
}

.dropdown-menu li > a:hover,
.dropdown-menu li > a:focus,
.dropdown-submenu:hover > a {
  background-color: #009cea;
}

.dropdown-menu .active > a,
.dropdown-menu .active > a:hover {
  background-color: #009cea;
}

/* overwrite bootstrap button style */
.btn-theme.active,
.btn-primary.active,
.btn-warning.active,
.btn-danger.active,
.btn-success.active,
.btn-info.active,
.btn-inverse.active {
  color: rgba(255, 255, 255, 0.75);
}

.btn-theme {
  color: #ffffff;
  text-shadow: none;
  border: none;
}

.btn-theme:hover,
.btn-theme:focus,
.btn-theme:active,
.btn-theme.active,
.btn-theme.disabled,
.btn-theme[disabled] {
  color: #ffffff;
}

.btn-primary {
  color: #ffffff;
  text-shadow: none;
  background-color: #065388;
  *background-color: #065388;
  background-image: linear-gradient(to bottom, #065388, #065388);
  background-repeat: repeat-x;
  border-color: #065388 #065388 #065388;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff065388', endColorstr='#ff065388', GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.btn-primary.disabled,
.btn-primary[disabled] {
  color: #ffffff;
  background: #05416c;
  *background-color: #05416c;
}

.btn-primary:active,
.btn-primary.active {
  background-color: #05416c \9;
}

.btn-warning {
  color: #ffffff;
  text-shadow: none;
  background-color: #fab600;
  *background-color: #fab600;
  background-image: linear-gradient(to bottom, #fab600, #fab600);
  background-repeat: repeat-x;
  border-color: #fab600 #fab600 #fab600;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fffab600', endColorstr='#fffab600', GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
}

.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active,
.btn-warning.disabled,
.btn-warning[disabled] {
  color: #ffffff;
  background: #e1a503;
  *background: #e1a503;
}

.btn-warning:active,
.btn-warning.active {
  background: #e1a503 \9;
}

.btn-danger {
  color: #ffffff;
  text-shadow: none;
  background-color: #bf0707;
  *background-color: #bf0707;
  background-image: linear-gradient(to bottom, #bf0707, #bf0707);
  background-repeat: repeat-x;
  border-color: #bf0707 #bf0707 #bf0707;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffbf0707', endColorstr='#ffbf0707', GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active,
.btn-danger.active,
.btn-danger.disabled,
.btn-danger[disabled] {
  color: #ffffff;
  background: #a10404;
  *background: #a10404;
}

.btn-danger:active,
.btn-danger.active {
  background: #a10404 \9;
}

.btn-success {
  color: #ffffff;
  text-shadow: none;
  background-color: #337e0a;
  *background-color: #337e0a;
  background-image: linear-gradient(to bottom, #337e0a, #337e0a);
  background-repeat: repeat-x;
  border-color: #337e0a #337e0a #337e0a;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff337e0a', endColorstr='#ff337e0a', GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
}

.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active,
.btn-success.disabled,
.btn-success[disabled] {
  color: #ffffff;
  background: #286507;
  *background: #286507;
}

.btn-success:active,
.btn-success.active {
  background: #286507 \9;
}

.btn-info {
  color: #ffffff;
  text-shadow: none;
  background-color: #048d9d;
  *background-color: #048d9d;
  background-image: linear-gradient(to bottom, #048d9d, #048d9d);
  background-repeat: repeat-x;
  border-color: #048d9d #048d9d #048d9d;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff048d9d', endColorstr='#ff048d9d', GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
}

.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active,
.btn-info.disabled,
.btn-info[disabled] {
  color: #ffffff;
  background: #02727f;
  *background: #02727f;
}

.btn-info:active,
.btn-info.active {
  background: #02727f \9;
}

.btn-inverse {
  color: #ffffff;
  text-shadow: none;
  background-color: #3c3c3c;
  *background-color: #3c3c3c;
  background-image: linear-gradient(to bottom, #3c3c3c, #3c3c3c);
  background-repeat: repeat-x;
  border-color: #3c3c3c #3c3c3c #3c3c3c;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff3c3c3c', endColorstr='#ff3c3c3c', GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
}

.btn-inverse:hover,
.btn-inverse:focus,
.btn-inverse:active,
.btn-inverse.active,
.btn-inverse.disabled,
.btn-inverse[disabled] {
  color: #ffffff;
  background: #2b2a2a;
  *background: #2b2a2a;
}

.btn-inverse:active,
.btn-inverse.active {
  background: #2b2a2a \9;
}

/* end twitter bootstrap button style */

button.btn,
input[type='submit'].btn {
  *padding-top: 3px;
  *padding-bottom: 3px;
}

button.btn::-moz-focus-inner,
input[type='submit'].btn::-moz-focus-inner {
  padding: 0;
  border: 0;
}

button.btn.btn-large,
input[type='submit'].btn.btn-large {
  *padding-top: 7px;
  *padding-bottom: 7px;
}

button.btn.btn-small,
input[type='submit'].btn.btn-small {
  *padding-top: 3px;
  *padding-bottom: 3px;
}

button.btn.btn-mini,
input[type='submit'].btn.btn-mini {
  *padding-top: 1px;
  *padding-bottom: 1px;
}

.btn-link,
.btn-link:active,
.btn-link[disabled] {
  background-color: transparent;
  background-image: none;
  box-shadow: none;
}

.btn-link {
  border: none;
  border-radius: 0;
}

.btn-link:hover {
  color: #005580;
  text-decoration: underline;
  background-color: transparent;
}

.btn-link[disabled]:hover {
  color: #333333;
  text-decoration: none;
}

.btn-group {
  position: relative;
  display: inline-block;
  *display: inline;
  /* IE7 inline-block hack */
  *zoom: 1;
  font-size: 0;
  vertical-align: middle;
  white-space: nowrap;
  *margin-left: 0.3em;
}

.btn-group:first-child {
  *margin-left: 0;
}

.btn-group > .btn {
  border-radius: 0;
}

.btn-group > .btn:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group > .btn:last-child,
.btn-group > .dropdown-toggle {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn.large:first-child {
  margin-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group > .btn.large:last-child,
.btn-group > .large.dropdown-toggle {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group .dropdown-toggle:active,
.btn-group.open .dropdown-toggle {
  outline: 0;
}

.btn-group > .btn + .dropdown-toggle {
  box-shadow: none;
}

.btn-group.open .dropdown-toggle {
  background-image: none;
  box-shadow: none;
}

.btn-group.open .btn.dropdown-toggle {
  background: #e6e6e6;
}

.btn-group.open .btn-primary.dropdown-toggle {
  background: #127b9b;
}

.btn-group.open .btn-warning.dropdown-toggle {
  background: #bcab37;
}

.btn-group.open .btn-danger.dropdown-toggle {
  background: #be332d;
}

.btn-group.open .btn-success.dropdown-toggle {
  background: #51a351;
}

.btn-group.open .btn-info.dropdown-toggle {
  background: #7fbfb1;
}

.btn-group.open .btn-inverse.dropdown-toggle {
  background: #222222;
}

.btn-large .caret {
  border-left-width: 5px;
  border-right-width: 5px;
  border-top-width: 5px;
}

.dropup .btn-large .caret {
  border-bottom-width: 5px;
}

.btn-primary .caret,
.btn-warning .caret,
.btn-danger .caret,
.btn-info .caret,
.btn-success .caret,
.btn-inverse .caret {
  border-top-color: #ffffff;
  border-bottom-color: #ffffff;
}

.btn-group-vertical > .btn {
  border-radius: 0;
}

.btn-group-vertical > .btn:first-child {
  border-radius: 0;
}

.btn-group-vertical > .btn:last-child {
  border-radius: 0;
}

.btn-group-vertical > .btn-large:first-child {
  border-radius: 0;
}

.btn-group-vertical > .btn-large:last-child {
  border-radius: 0;
}

.alert {
  border-radius: 0;
}

.nav-tabs > li > a {
  border: 1px solid transparent;
  border-radius: 0;
}

.nav-tabs > .active > a,
.nav-tabs > .active > a:hover {
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-bottom-color: transparent;
  cursor: default;
}

.nav-pills > li > a {
  border-radius: 0;
}

.nav-tabs.nav-stacked > li > a {
  border-radius: 0;
}

.nav-tabs.nav-stacked > li:first-child > a {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.nav-tabs.nav-stacked > li:last-child > a {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.nav-tabs .dropdown-menu {
  border-radius: 0;
}

.nav-pills .dropdown-menu {
  border-radius: 0;
}

.nav .active .dropdown-toggle .caret {
  border-top-color: #fff;
  border-bottom-color: #fff;
}

.nav-tabs .open .dropdown-toggle,
.nav-pills .open .dropdown-toggle,
.nav > li.dropdown.open.active > a:hover {
  color: #ffffff;
  background-color: #999999;
  border-color: #999999;
}

.nav li.dropdown.open .caret,
.nav li.dropdown.open.active .caret,
.nav li.dropdown.open a:hover .caret {
  border-top-color: #ffffff;
  border-bottom-color: #ffffff;
  opacity: 1;
  filter: alpha(opacity=100);
}

.tabs-below > .nav-tabs > li > a {
  border-radius: 0;
}

.tabs-below > .nav-tabs > li > a:hover {
  border-bottom-color: transparent;
  border-top-color: #ddd;
}

.tabs-below > .nav-tabs > .active > a,
.tabs-below > .nav-tabs > .active > a:hover {
  border-color: transparent #ddd #ddd #ddd;
}

.tabs-left > .nav-tabs > li > a {
  border-radius: 0;
}

.tabs-right > .nav-tabs > li > a {
  border-radius: 0;
}

.nav > .disabled > a:hover {
  text-decoration: none;
  background-color: transparent;
  cursor: default;
}

.navbar-inverse .navbar-inner {
  background: #262626;
  border-color: #262626;
}

.navbar-inverse .nav .active > a,
.navbar-inverse .nav .active > a:hover,
.navbar-inverse .nav .active > a:focus {
  color: #ffffff;
}

.navbar-inner {
  border-radius: 0;
  box-shadow: none;
}

.navbar .divider-vertical {
  border-left: 1px solid #f2f2f2;
  border-right: 1px solid #ffffff;
}

.navbar-search .search-query {
  border-radius: 0;
}

.navbar-static-top {
  position: static;
  margin-bottom: 0;
}

.navbar-static-top .navbar-inner {
  border-radius: 0;
}

.navbar-fixed-top .navbar-inner,
.navbar-fixed-bottom .navbar-inner {
  padding-left: 0;
  padding-right: 0;
  border-radius: 0;
}

.navbar-fixed-top {
  top: 0;
}

.navbar-fixed-top .navbar-inner,
.navbar-static-top .navbar-inner {
  box-shadow: none;
}

.navbar-fixed-bottom {
  bottom: 0;
}

.navbar-fixed-bottom .navbar-inner {
  box-shadow: none;
}

.navbar .nav > .active > a,
.navbar .nav > .active > a:hover,
.navbar .nav > .active > a:focus {
  box-shadow: none;
}

.navbar .btn-navbar {
  background: #ededed;
  border-color: #e5e5e5;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #e5e5e5;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  box-shadow: none;
}

.navbar .btn-navbar .icon-bar {
  border-radius: 0;
  box-shadow: none;
}

.navbar .nav > li > .dropdown-menu:before {
  content: '';
  display: inline-block;
  border-left: none;
  border-right: none;
  border-bottom: none;
  border-bottom-color: none;
  position: absolute;
  top: -7px;
  left: 9px;
}

.navbar .nav > li > .dropdown-menu:after {
  content: '';
  display: inline-block;
  border-left: none;
  border-right: none;
  border-bottom: none;
  position: absolute;
  top: -6px;
  left: 10px;
}

.navbar-fixed-bottom .nav > li > .dropdown-menu:before {
  border-top: 7px solid #e9e9e9;
  border-top-color: transparent;
  border-bottom: 0;
  bottom: -7px;
  top: auto;
}

.navbar-fixed-bottom .nav > li > .dropdown-menu:after {
  border-top: 6px solid #ffffff;
  border-bottom: 0;
  bottom: -6px;
  top: auto;
}

.navbar .pull-right > li > .dropdown-menu .dropdown-menu,
.navbar .nav > li > .dropdown-menu.pull-right .dropdown-menu {
  border-radius: 0;
}

.navbar-inverse .navbar-search .search-query {
  box-shadow: none;
}

.navbar-inverse .navbar-search .search-query:focus,
.navbar-inverse .navbar-search .search-query.focused {
  border: 0;
  box-shadow: none;
  outline: 0;
}

.navbar-inverse .btn-navbar {
  border-color: #040404;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background: #040404;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}

.breadcrumb {
  padding: 8px 0;
  background-color: #fff;
  border-radius: 0;
}

.breadcrumb > li {
  text-shadow: none;
}

.breadcrumb li span {
  color: #aaa;
  padding: 0 5px;
}

.pagination ul {
  border-radius: 0;
  box-shadow: none;
}

.pagination ul > li:first-child > a,
.pagination ul > li:first-child > span {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.pagination ul > li:last-child > a,
.pagination ul > li:last-child > span {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.pagination-large ul > li:first-child > a,
.pagination-large ul > li:first-child > span {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.pagination-large ul > li:last-child > a,
.pagination-large ul > li:last-child > span {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.pagination-mini ul > li:first-child > a,
.pagination-small ul > li:first-child > a,
.pagination-mini ul > li:first-child > span,
.pagination-small ul > li:first-child > span {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.pagination-mini ul > li:last-child > a,
.pagination-small ul > li:last-child > a,
.pagination-mini ul > li:last-child > span,
.pagination-small ul > li:last-child > span {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.pager li > a,
.pager li > span {
  border-radius: 0;
}

.modal {
  border-radius: 0;
  box-shadow: none;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding-box;
  background-clip: padding-box;
  outline: none;
}

.modal-form {
  margin-bottom: 0;
}

.modal-footer {
  border-radius: 0;
  box-shadow: none;
}

.tooltip-inner {
  border-radius: 0;
}

.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.tooltip.bottom .tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000000;
}

.popover {
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  border-radius: 0;
  box-shadow: none;
}

.popover-title {
  border-radius: 0;
}

.popover.top .arrow {
  left: 50%;
  margin-left: -11px;
  border-bottom-width: 0;
  border-top-color: #999;
  border-top-color: rgba(0, 0, 0, 0.25);
  bottom: -11px;
}

.popover.top .arrow:after {
  bottom: 1px;
  margin-left: -10px;
  border-bottom-width: 0;
  border-top-color: #ffffff;
}

.popover.right .arrow {
  top: 50%;
  left: -11px;
  margin-top: -11px;
  border-left-width: 0;
  border-right-color: #999;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.popover.right .arrow:after {
  left: 1px;
  bottom: -10px;
  border-left-width: 0;
  border-right-color: #ffffff;
}

.popover.bottom .arrow {
  left: 50%;
  margin-left: -11px;
  border-top-width: 0;
  border-bottom-color: #999;
  border-bottom-color: rgba(0, 0, 0, 0.25);
  top: -11px;
}

.popover.bottom .arrow:after {
  top: 1px;
  margin-left: -10px;
  border-top-width: 0;
  border-bottom-color: #ffffff;
}

.popover.left .arrow {
  top: 50%;
  right: -11px;
  margin-top: -11px;
  border-right-width: 0;
  border-left-color: #999;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.popover.left .arrow:after {
  right: 1px;
  border-right-width: 0;
  border-left-color: #ffffff;
  bottom: -10px;
}

.thumbnail {
  border-radius: 0;
  box-shadow: none;
}

a.thumbnail:hover {
  box-shadow: none;
}

.label {
  border-radius: 0;
}

.badge {
  border-radius: 0;
}

.progress {
  box-shadow: none;
  border-radius: 0;
}

.progress .bar {
  box-shadow: none;
  box-sizing: border-box;
}

.progress .bar + .bar {
  box-shadow: none;
}

.accordion-group {
  border-radius: 0;
}

.accordion-heading {
  border-bottom: 0;
}

.carousel-control {
  border-radius: 0;
  opacity: 0.5;
  filter: alpha(opacity=50);
}

.carousel-control.right {
  left: auto;
  right: 15px;
}

.carousel-control:hover {
  color: #ffffff;
  text-decoration: none;
  opacity: 0.9;
  filter: alpha(opacity=90);
}

.carousel-caption {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 15px;
  background: #333333;
  background: rgba(0, 0, 0, 0.75);
}

.carousel.slide {
  max-width: 700px;
}

.hero-unit {
  border-radius: 0;
}

html,
body {
  min-height: 100vh;
}

.flex-wrapper {
  min-height: 100vh;
  overflow-x: hidden;
}

footer {
  margin-top: 60px;
  padding-top: 30px;
  color: #7e7e7e;
  width: 100%;
}

footer.footer {
  background: #151515;
}

footer h1,
footer h2,
footer h3,
footer h4,
footer h5,
footer h6 {
  color: #e8e8e8;
}

footer .block-a {
  background: #bcab37;
}

footer .verybottom {
  padding-top: 30px;
  background: #111;
}

footer ul.regular li a,
footer .nav-list li.nav-header {
  text-shadow: none;
  color: #b8b8b8;
}

footer ul.regular,
footer ul.nav {
  margin-left: 0;
  list-style: none;
}

footer ul.regular li a:hover {
  text-decoration: none;
}

footer .nav-list.regular > li > a {
  padding: 0;
  margin-left: 0;
}

footer .nav-list li.divider {
  text-shadow: none;
  *width: 100%;
  height: 1px;
  margin: 9px 1px;
  *margin: -5px 0 5px;
  overflow: hidden;
  background-color: #222;
  border-bottom: none;
}

footer .twitter li {
  border-bottom: 1px dashed #2d2d2d;
  padding-bottom: 20px;
  margin-bottom: 20px;
  list-style: none;
}

ul.social {
  list-style: none;
  margin-left: 0;
}

ul.social li {
  display: inline;
}

/* misc */
.center {
  text-align: center;
}

/* img */
img.icon {
  margin-bottom: 10px;
}

img.max-img {
  max-width: 100%;
  height: auto;
}

/* effects */
.rotate:hover {
  /* IE 8 */
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=80)';

  /* IE 5-7 */
  filter: alpha(opacity=80);

  /* Netscape */
  -moz-opacity: 0.7;

  /* Safari 1.x */
  -khtml-opacity: 0.7;

  /* Good browsers */
  opacity: 0.7;
}

/* box */
.box {
  border-radius: 2px;
  border: 1px solid #e9e9e9;
  background: #fcfcfc;
  padding: 20px;
}

.features {
  margin-bottom: 30px;
}

.features i.left {
  margin-right: 10px;
}

.features h4 {
  margin-top: 2px;
}

.divcenter {
  text-align: center;
}

.maincontent {
  margin-top: 10px;
  margin-bottom: 250px;
}

.icon-square,
.icon-rounded,
.icon-plain,
.icon-circled {
  margin: 10px 10px 10px 0;
  display: inline-block !important;
  text-align: center !important;
  vertical-align: middle;
  color: #fff;
  width: 40px;
  height: 40px;
  padding: 3px;
  font-size: 12px;
  line-height: 18px;
  text-shadow: none;
  cursor: pointer;
}

.icon-rounded {
  background-color: #444;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
}

.icon-circled {
  background-color: #444;
  border-radius: 30px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}
.icon-square {
  background-color: #444;
}
.icon-plain {
  width: 48px;
  height: 48px;
  font-size: 47px;
  line-height: 50px;
  color: #444;
  text-shadow: 1px 1px 1px #fff;
}

.icon-plain:hover {
  color: #777;
}

/* circled radius */
.icon-circled {
  border-radius: 24px;
  -moz-border-radius: 24px;
  -webkit-border-radius: 24px;
}
.icon-64.icon-circled {
  border-radius: 32px;
  -moz-border-radius: 32px;
  -webkit-border-radius: 32px;
}
.icon-128.icon-circled {
  border-radius: 64px;
  -moz-border-radius: 64px;
  -webkit-border-radius: 64px;
}

.icon-light {
  background-color: #f5f5f5;
  color: #444;
  text-shadow: 1px 1px 1px #fff;
  box-shadow: inset 0 0 15px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: inset 0 0 15px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: inset 0 0 15px rgba(0, 0, 0, 0.2);
}

.icon-rounded:hover,
.icon-circled:hover {
  color: #fff;
  text-shadow: none;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 0 rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 0 0 rgba(0, 0, 0, 0.2);
}

/* custom size */
.icon-32 {
  width: 24px;
  height: 24px;
}
.icon-48 {
  width: 32px;
  height: 32px;
  padding: 8px;
}
.icon-64 {
  width: 40px;
  height: 40px;
  padding: 12px;
  font-size: 30px;
  line-height: 40px;
}
.icon-128 {
  width: 56px;
  height: 56px;
  padding: 36px;
  font-size: 54px;
  line-height: 60px;
}

/* Blog */

aside {
  background: #fcfcfc;
  padding: 20px 15px 0 15px;
  margin: 0 0 30px 0;
  border: 1px solid #e6e6e6;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.03);
  box-sizing: border-box;
}

aside .widget {
  margin-bottom: 30px;
}

aside .widget ul {
  list-style: none;
  margin-left: 0;
}

aside .widget ul li {
  border-bottom: 1px dotted #efefef;
  margin: 15px 0 0 0;
  padding-bottom: 5px;
}

aside .widget h4 {
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

/* categories */
aside .widget ul.cat {
  list-style: none;
  margin-left: 0;
}
aside .widget ul.cat li {
  padding-left: 24px;
  background-repeat: no-repeat;
  background-position: -456px -69px;
}
aside .widget ul.cat li a {
  color: #444;
}
aside .widget ul.cat li a:hover {
  text-decoration: none;
}

/* recent posts */

aside .widget ul.recent-posts li {
  overflow: hidden;
  display: inline-block;
}

aside .widget ul.recent-posts li a {
  color: #444;
  text-align: left;
  font-weight: 600;
}

aside .widget ul.recent-posts li a:hover {
  text-decoration: none;
}

aside .widget ul.recent-posts li span.date {
  float: left;
  font-size: 11px;
  margin-top: 10px;
}

aside .widget ul.recent-posts li span.comment {
  float: left;
  margin-left: 20px;
  font-size: 11px;
  margin-top: 10px;
}

/* tags */
aside .widget ul.tags li {
  display: inline;
  border: none;
}

aside .widget ul.tags li a {
  margin-bottom: 10px;
  border: none;
  color: #fff;
}

.widget .social-links {
  list-style: none;
}

.widget .social-links li {
  padding: 0;
  border: none;
  float: none;
  display: inline;
}

.social-links li a:hover {
  text-decoration: none;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: 0 auto;
}
.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}

.registerSubmit {
  float: right;
}

section#intro {
  background: #f5f5f5;
  padding: 0;
  margin: 0;
  border-bottom: 1px solid #e9e9e9;
  border-top: 1px solid #e9e9e9;
  padding: 30px;
  margin-bottom: 30px;
}

.react-tel-input .form-control {
  height: 60px;
}

